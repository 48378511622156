<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems" />
    <div class="custom-search d-flex justify-content-between mb-1">
      <div class="d-flex align-items-center">
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="showDownloadModal = true"
          v-if="
            $can('backoffice-payment-history.generate-all-pdf') &&
            $can('backoffice-payment-history.generate-pdf')
          "
        >
          {{ $t("Download All") }}
        </b-button>
      </div>
      <b-form-group>
        <div class="d-flex align-items-center">
          <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
          <b-form-select
            v-model="pageLength"
            :options="['25', '50', '100']"
            class="ml-1"
            @input="(value) => onPerPageChange({ pageLength: value })"
          />
        </div>
      </b-form-group>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="dextop-table-view">
      <div class="table-responsive api-keys-table">
        <!-- table -->
        <vue-good-table
          styleClass="vgt-table striped"
          :columns="columns"
          :fixed-header="false"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
            trigger: 'enter',
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <span
              class="d-flex align-items-center justify-content-center gap-2"
              v-if="props.column.field === 'action'"
            >
              <div
                @click="generatePdf(props.row)"
                class="cursor-pointer"
                :title="$t('Generate Document')"
                v-if="$can('backoffice-payment-history.generate-pdf')"
              >
                <feather-icon size="16" icon="FileTextIcon" />
              </div>
            </span>
            <span v-if="props.column.field === 'costs'">{{
              $formatter(props.row.costs)
            }}</span>
            <span v-else-if="props.column.field === 'Company.companyName'">
              {{ props.row.reseller ? props.row.reseller.companyName : "" }}
            </span>
            <span v-else-if="props.column.field === 'salesPartner'">{{
              props.row.salesPartner ? props.row.salesPartner.companyName : ""
            }}</span>
            <span v-else-if="props.column.field === 'created_at'">{{
              $dateFormatter(props.row.created_at, $i18n.locale)
            }}</span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="totalRecords"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => onPageChange({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="reponsive-view-cards">
      <b-row>
        <b-col cols="12" lg="4" md="6" v-for="row in rows" :key="row.id">
          <div class="card">
            <div class="card-header">
              <h5>{{ row.companyName }}</h5>
              <div class="d-flex align-items-center gap-2">
                <div
                  @click="generatePdf(row)"
                  class="cursor-pointer"
                  :title="$t('Generate Document')"
                  v-if="$can('backoffice-payment-history.generate-pdf')"
                >
                  <feather-icon size="18" icon="FileTextIcon" />
                </div>
              </div>
            </div>
            <div class="card-body">
              <ul class="card-list">
                <li>
                  <div class="left">
                    <strong>{{ $t("Reseller") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{
                      row.reseller ? row.reseller.companyName : ""
                    }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Sales Partner") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{
                      row.salesPartner ? row.salesPartner.companyName : ""
                    }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Costs") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{
                      $formatter(row.costs, $i18n.locale, "EUR", false, 2, 2)
                    }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("DentalTwin Coins") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.credits }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Session ID") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.sessionId }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Status") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.status }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Created Date") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{
                      $dateFormatter(row.created_at, $i18n.locale)
                    }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-center mt-2">
            <b-pagination
              :value="1"
              :total-rows="totalRecords"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => onPageChange({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </div>
    <!--======        <!-- Download Modal -->
    <b-modal
      v-model="showDownloadModal"
      :title="$t('Download Payment History')"
      hide-footer
    >
      <b-form @submit.prevent="generateAllPdf">
        <b-row>
          <b-col cols="12" class="mb-2">
            <validation-provider
              #default="{ errors }"
              name="Start Time"
              rules="required"
            >
              <DatePicker
                v-model="downloadDateRange.startDate"
                :label="$t('Start Time')"
                :required="true"
                :isLeftIcon="true"
                :options="{
                  enableTime: true,
                }"
                :leftIconName="'dateIcon'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12" class="mb-2">
            <validation-provider
              #default="{ errors }"
              name="End Time"
              rules="required"
            >
              <DatePicker
                v-model="downloadDateRange.endDate"
                :label="$t('End Time')"
                :required="true"
                :isLeftIcon="true"
                :options="{
                  enableTime: true,
                }"
                :leftIconName="'dateIcon'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12" class="d-flex justify-content-end">
            <b-button
              variant="secondary"
              class="mr-1"
              @click="showDownloadModal = false"
            >
              {{ $t("Cancel") }}
            </b-button>
            <b-button variant="primary" type="submit">
              {{ $t("Download") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Multiselect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DatePicker from "@/components/DatePicker.vue";
export default {
  components: {
    PageHeader,
    Multiselect,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
  },

  computed: {
    optionalItems() {
      return {
        createBtn1: {
          show: false,
          text: this.$t(""),
          icon: "PlusIcon",
          path: "",
          permission: "",
        },
      };
    },
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Payment History"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          label: this.$t("Company"),
          field: "companyName",
        },
        {
          label: this.$t("Reseller"),
          field: "Company.companyName",
        },
        {
          label: this.$t("Sales Partner"),
          field: "salesPartner",
        },
        {
          label: this.$t("Costs"),
          field: "costs",
        },
        {
          label: this.$t("DentalTwin Coins"),
          field: "credits",
        },
        {
          label: this.$t("Session ID"),
          field: "sessionId",
        },
        {
          label: this.$t("Status"),
          field: "status",
        },
        {
          label: this.$t("Created Date"),
          field: "created_at",
        },
        {
          label: this.$t("Action"),
          field: "action",
          sortable: false, // disable sorting on this column
        },
      ];
    },
  },
  data() {
    return {
      totalRecords: 0,
      pageLength: 25,
      page: 1,
      form: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
      rows: [],
      searchTerm: "",
      templateId: null,
      showDownloadModal: false,
      downloadDateRange: {
        startDate: null,
        endDate: null,
      },
    };
  },

  async created() {
    const response = await this.$store.dispatch(
      "invoices/documentAssignmentList"
    );
    this.templateId = response?.data?.data?.paymentHistoryTemplateId ?? null;
    this.loadItems();
  },

  methods: {
    async generateAllPdf() {
      const response = await this.$store.dispatch(
        "packages/generatePaymentHistoryPdf",
        {
          startDate: this.downloadDateRange.startDate,
          endDate: this.downloadDateRange.endDate,
        }
      );
      response?.data?.data?.forEach(async (data) => {
        await this.generatePdf(data);
      });
      this.downloadDateRange = {
        startDate: null,
        endDate: null,
      };
      this.showDownloadModal = false;
    },
    async generatePdf(row) {
      const payload = {
        ...row,
        id: this.templateId,
        output: "pdf",
        updatedTime: Date.now(),
        created_at: row.created_at
          ? this.$dateFormatter(row.created_at, this.$i18n.locale)
          : null,
      };
      const filename = `payment-history-${this.$dateFormatter(
        row.created_at,
        this.$i18n.locale
      )}.pdf`;

      this.$store.commit("showLoader", true);
      const response = await this.$store
        .dispatch("documentService/processTemplate", {
          data: payload,
          filename: filename,
          documentType: "pdf",
        })
        .finally(() => {
          this.$store.commit("showLoader", false);
        });

      if (response instanceof Blob) {
        await this.convertBlobToBase64(response);
      }
    },
    convertBlobToBase64(data) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onerror = (err) => {
          reject(err);
        };
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },

    onSortChange(params) {
      this.form.sortOrder = params[0].type;
      if (params[0].type == "none") this.form.sortOrder = "asc";
      this.form.sortBy = params[0].field;
      if (params[0].field == "salesPartner") {
        this.form.sortBy = "Company.companyName";
      }
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        let response = await this.$store.dispatch(
          "packages/getPaymentHistory",
          {
            page: this.page,
            ...this.form,
            perPage: this.pageLength,
          }
        );
        this.rows = response?.data?.data;
        this.totalRecords = response?.data?.total;
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
  },
};
</script>
